<!-- @format -->
<template>
  <div>
    <gallery
      v-if="$config.gallery.display"
      class="hidden"
      :images="images"
      :index="foto"
      @close="$parent.index = null"
    />
  </div>
</template>

<script>
  import VueGallery from 'vue-gallery'

  export default {
    components: {
      gallery: VueGallery,
    },
    props: {
      foto: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        images: [],
      }
    },
    mounted() {
      for (let index = 0; index < this.$config.gallery.count; index++) {
        this.images[index] = require(`@/assets/images/gallery/` +
          (index + 1) +
          `.jpg`)
      }
    },
  }
</script>

<style lang="scss">
  .btn {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1111;
  }
  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
  }
</style>
